import Immutable from 'immutable';

import { GET_SCANS, GET_SCAN, GET_SNAPSHOT, RESET_SCAN } from '../constants';

const INITIAL_STATE = Immutable.fromJS({scans: [], scansCount: 0, scan: {Snapshots: [], Car: {}, AnalysisResult: {}, Customer: {}, Followers: [], Files: []}});

export default function scansReducer(state = INITIAL_STATE, action)
{
	let nextState = state.toJS();

	switch (action.type) {
		case GET_SCANS:
            nextState.scans = action.payload.rows
            nextState.scansCount = action.payload.count
        break;
        case GET_SCAN:
            nextState.scan = {...nextState.scan, ...action.payload}
        break;
        case GET_SNAPSHOT:
            nextState.scan.Snapshots.push(action.payload)
        break;
        case RESET_SCAN:
            nextState.scan = INITIAL_STATE.toJS().scan;
        break;
        default:

        break;
	}
	return state.merge(nextState);
}
