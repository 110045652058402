import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const Pagination = ({ defaultPageSize, current, pageSize, onChange, total, showPageInput = false }) => {
  const totalPages = Math.ceil(total / pageSize);
  const pageNeighbors = 2;

  const [pageInput, setPageInput] = useState(current);

  useEffect(() => {
    // Whenever the current page changes, update the page input value to match
    setPageInput(current);
  }, [current]);

  const handleClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      onChange(page);
    }
  };

  const handleNext = () => {
    if (current < totalPages) {
      onChange(current + 1);
    }
  };

  const handlePrev = () => {
    if (current > 1) {
      onChange(current - 1);
    }
  };

  // Handle page number input
  const handlePageInputChange = (e) => {
    const value = e.target.value;
    if (/^\d+$/.test(value) || value === "") { // Only allow numbers or empty input
      setPageInput(value);
    }
  };

  const handlePageInputSubmit = () => {
    const pageNumber = Number(pageInput);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onChange(pageNumber);
    } else {
      // Optionally reset the input if invalid
      setPageInput(current);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handlePageInputSubmit();
    }
  };

  const createRange = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };

  const renderPageNumbers = () => {
    const totalNumbers = pageNeighbors * 2 + 3; // Total numbers to display (including current page)
    const totalBlocks = totalNumbers + 2; // Total blocks (including the first and last pages)

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, current - pageNeighbors);
      const endPage = Math.min(totalPages - 1, current + pageNeighbors);
      let pages = createRange(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = endPage < totalPages - 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      if (hasLeftSpill && !hasRightSpill) {
        const extraPages = createRange(startPage - spillOffset, startPage - 1);
        pages = ['...', ...extraPages, ...pages];
      } else if (!hasLeftSpill && hasRightSpill) {
        const extraPages = createRange(endPage + 1, endPage + spillOffset);
        pages = [...pages, ...extraPages, '...'];
      } else if (hasLeftSpill && hasRightSpill) {
        pages = ['...', ...pages, '...'];
      }

      return [1, ...pages, totalPages].map((page, index) => (
        <li key={index} className={`page-item ${current === page ? 'active' : ''}`}>
          {typeof page === 'number' ? (
            <button className="page-link" onClick={() => handleClick(page)}>
              {page}
            </button>
          ) : (
            <span className="page-link">...</span>
          )}
        </li>
      ));
    }

    return createRange(1, totalPages).map((page) => (
      <li key={page} className={`page-item ${current === page ? 'active' : ''}`}>
        <button className="page-link" onClick={() => handleClick(page)}>
          {page}
        </button>
      </li>
    ));
  };

  return (
    <nav aria-label="Pagination" className="d-flex justify-content-center align-items-center flex-wrap">
      <ul className="pagination mb-0">
        <li className={`page-item ${current === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={handlePrev} aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {renderPageNumbers()}
        <li className={`page-item ${current === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={handleNext} aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>

      {showPageInput && (
        <div className="input-group ml-3" style={{ maxWidth: '180px' }}>
          <input
            type="text"
            className="form-control"
            value={pageInput}
            onChange={handlePageInputChange}
            onKeyPress={handleKeyPress}
            placeholder={`Page (1 - ${totalPages})`}
          />
          <div className="input-group-append">
            <button className="btn btn-primary" onClick={handlePageInputSubmit}>
              Go
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

Pagination.propTypes = {
  defaultPageSize: PropTypes.number,
  current: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  showPageInput: PropTypes.bool, 
};

export default injectIntl(Pagination);