import moment from "moment";
import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm } from "redux-form/immutable";
import { saveSnapShot } from "../../actions/scansActions";
import { selectHistory, selectILoading } from "../../selectors/mainSelector";
import Video from "../VideoPlayer";
import { FormattedMessage, injectIntl } from "react-intl";
import { videoModalStyle } from "../../constants";

const VideoModal = ({
  openModal,
  closeModal,
  messages,
  intl,
  scanId,
  scanCreatedAt,
  video_link,
}) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const i_loading = useSelector(selectILoading);
  const location = useSelector(selectHistory);

  const uuidRegex = new RegExp(
    /scan\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
    "i"
  );

  const dispatch = useDispatch();

  const videoState = (state) => {
    setVideoLoaded(state);
  };

  const makeSnapShot = () => {
    var video = document.getElementsByTagName("video")[0];
    var canvas = document.createElement("canvas");
    var w, h, ratio;
    ratio = video.videoWidth / video.videoHeight;
    w = video.videoWidth - 100;
    h = parseInt(w / ratio, 10);
    canvas.width = w;
    canvas.height = h;
    var context = canvas.getContext("2d");
    context.fillRect(0, 0, w, h);
    context.drawImage(video, 0, 0, w, h);

    var imData = canvas.toDataURL("image/jpeg", 0.7);
    dispatch(saveSnapShot({ data: imData }, scanId, intl));
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={videoModalStyle}
    >
      <div className="modal-body">
        <h4 className="mb-4 font-medium text-2xl">
          {moment(scanCreatedAt).format("MMM D, YYYY | HH:MM")}
        </h4>
        <Video videoLink={video_link} videoState={videoState} />
        {videoLoaded && !uuidRegex.test(location.pathname) && (
          <div className="row mr-0 ml-0">
            <div className="col-md-12 text-right mt-2">
              {!i_loading ? (
                <button
                  disabled={i_loading}
                  onClick={makeSnapShot}
                  className="btn btn-success"
                >
                  <i className="fa fa-check"></i>{" "}
                  <FormattedMessage {...messages.snapshotString} />
                </button>
              ) : (
                <img
                  width="50"
                  src="../assets/images/loading-gif.gif"
                  alt="loading"
                />
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default reduxForm({
  enableReinitialize: true,
  form: "AddFollowersForm",
})(injectIntl(VideoModal));
