import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addFollowers,
  getScan,
  getScanFileData,
  getScanSnapshotData,
  resetScan,
} from "../../../actions/scansActions";
import { getScanSelector } from "../../../selectors/scansSelector";
import messages from "../languages";
import { FormattedMessage, injectIntl } from "react-intl";
import FollowersModal from "../../../components/FollowersModal";
import ModalVideo from "../../../components/PlayerModal";
import Carousel, { Modal, ModalGateway } from "react-images";

const ScanDetailsLabel = ({label, value}) => {
  return (
    <div className="col-md-3 col-sm-4 mb-4">
      <div className="font-weight-bold">{label}</div>
      <div className="mt-1">{value ?? "N/A"}</div>
  </div>
  )
}

const ScanDetailsCard = ({title, children}) => {
  return (
    <div className="card rounded-lg mt-3">
    <div className="card-body">
      <h4 className="card-title mb-3 text-dark font-weight-bold">{title}</h4>
      {children}
    </div>
  </div>
  )
}

const ScanDetailsPage = ({ location, intl, match }) => {
  const [currentImage, setCurrentImage] = useState(null);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [isFollowersModalOpen, setisFollowersModalOpen] = useState(false);
  const [isPlayerModalOpen, setisPlayerModalOpen] = useState(false);
  const [videoURL, setvideoURL] = useState(null);

  const dispatch = useDispatch();

  const scan = useSelector(getScanSelector);

  const reportFiles = scan.Files.filter((f) => {
    return f.type.toLowerCase() === "report";
  });

  const videoFiles = scan.Files.filter((f) => {
    return f.type.toLowerCase() === "video";
  });

  const [thumbState, setThumbState] = useState([]);
  const [thumbsLoaded, setThumbsLoaded] = useState(false);

  useEffect(() => {
    if (thumbsLoaded) return;
    if (scan.Files.length === 0) return;
    setThumbsLoaded(true);

    scan.Files
      .filter((f) => f.type.toLowerCase() === "thumbnail")
      .forEach(v => {
        getScanFileData(v.id).then((response) => {
          if (response.status === 200) {
            setThumbState((prev) => [...prev, {
              id: v.id,
              link: response.data.link,
              location: v.location
            }]);
          }
        });
      });
  }, [scan, thumbsLoaded]);

  const openLightbox = (snap) => () => {
    getScanSnapshotData(snap.scanId, snap.id).then((response) => {
      if (response.status === 200) {
        setCurrentImage(response.data.link);
        setViewerIsOpen(true);
      }
    });
  };

  const closeLightbox = () => {
    setCurrentImage(null);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getScan(match.params.id, intl));

    return () => {
      dispatch(resetScan());
    }
  }, [dispatch, intl, match.params.id]);

  const getVideoStreamFileId = (videoFiles, title) => {
    var url = videoFiles.filter((element) => {
      var split = element.location.split("/");
      var file = split[split.length - 1];
      file = file.split(".");
      var fileName = file[0];

      return fileName === title;
    });

    if (url[0]) {
      return url[0].id;
    }
  };
  
  const getThumbnailLink = (thumbs, title) => {
    var url = thumbs.filter((element) => {
      var split = element.location.split("/");
      var file = split[split.length - 1];
      file = file.split(".");
      var fileName = file[0];

      return fileName === title;
    });

    if (url[0]) {
      return url[0].link;
    }
  };

  const playVideo = async (videoFileId) => {
    if (videoFileId == null) return;
    getScanFileData(videoFileId).then((response) => {
      if (response.status === 200) {
        setvideoURL(response.data.link);
        setisPlayerModalOpen(true);
      }
    });
  };

  const closeVideoModal = () => {
    setisPlayerModalOpen(false);
    setvideoURL(null);
  };

  const closeFModal = () => {
    setisFollowersModalOpen(false);
  };

  const openFModal = () => {
    setisFollowersModalOpen(true);
  };

  const addNewFollowers = (data) => {
    data = data
      .filter((fd) => {
        return typeof fd.id === "undefined";
      })
      .map((d) => {
        return { email: d.email, scanId: match.params.id };
      });
    if (data.length > 0) {
      dispatch(addFollowers(data, match.params.id, intl));
    }
  };

  const openReportFile = (file) => () => {
    getScanFileData(file.id).then((response) => {
      if (response.status === 200) {
        const link = document.createElement("a");
        link.href = response.data.link;
        link.download = "";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  const topVideo = getVideoStreamFileId(videoFiles, "Top");
  const leftVideo = getVideoStreamFileId(videoFiles, "Left");
  const leftRailVideo = getVideoStreamFileId(videoFiles, "LeftRail");
  const rightVideo = getVideoStreamFileId(videoFiles, "Right");
  const rightRailVideo = getVideoStreamFileId(videoFiles, "RightRail");

  return (
    <div className="page-wrapper h-100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">

            <h2 className="text-dark font-weight-bold mb-0">{intl.formatMessage(messages.scanDetails)}</h2>
            <h4>{scan.Car && scan.Car.licensePlate}</h4>

            <ScanDetailsCard title={intl.formatMessage(messages.scanString)}>
              <div className="row">
                <ScanDetailsLabel label={intl.formatMessage(messages.createdAtString)} value={moment(scan.createdAt).format("DD-MM-YYYY HH:MM")} />
                <ScanDetailsLabel label={intl.formatMessage(messages.finishedDateString)} value={moment(scan.finishedDate).format("DD-MM-YYYY HH:MM")} />
                <ScanDetailsLabel label={intl.formatMessage(messages.companyLabel)} value={scan.Company && scan.Company.name} />
              </div>

              <div className="row">
                <div className="col-md-6">
                  <img
                    src={getThumbnailLink(thumbState, "Top") ?? "../assets/images/avtocar.jpg"}
                    alt={intl.formatMessage(messages.top)}
                    className="img-fluid rounded-lg"
                    onClick={() => playVideo(topVideo)}
                    style={{cursor: topVideo ? "pointer" : "default"}}
                  />
                  <p className="mt-2">{intl.formatMessage(messages.top)}</p>
                </div>
                <div className="col-md-3">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <img
                        src={getThumbnailLink(thumbState, "Left") ?? "../assets/images/avtocar.jpg"}
                        alt={intl.formatMessage(messages.left)}
                        className="img-fluid rounded-lg"
                        onClick={() => playVideo(leftVideo)}
                        style={{cursor: leftVideo ? "pointer" : "default"}}
                      />
                      <p className="mt-2">{intl.formatMessage(messages.left)}</p>
                    </div>
                    <div className="col-12">
                      <img
                        src={getThumbnailLink(thumbState, "LeftRail") ?? "../assets/images/avtocar.jpg"}
                        alt={intl.formatMessage(messages.leftRail)}
                        className="img-fluid rounded-lg"
                        onClick={() => playVideo(leftRailVideo)}
                        style={{cursor: leftRailVideo ? "pointer" : "default"}}
                      />
                      <p className="mt-2">{intl.formatMessage(messages.leftRail)}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <img
                        src={getThumbnailLink(thumbState, "Right") ?? "../assets/images/avtocar.jpg"}
                        alt={intl.formatMessage(messages.right)}
                        className="img-fluid rounded-lg"
                        onClick={() => playVideo(rightVideo)}
                        style={{cursor: rightVideo ? "pointer" : "default"}}
                      />
                      <p className="mt-2">{intl.formatMessage(messages.right)}</p>
                    </div>
                    <div className="col-12">
                      <img
                        src={getThumbnailLink(thumbState, "RightRail") ?? "../assets/images/avtocar.jpg"}
                        alt={intl.formatMessage(messages.rightRail)}
                        className="img-fluid rounded-lg"
                        onClick={() => playVideo(rightRailVideo)}
                        style={{cursor: rightRailVideo ? "pointer" : "default"}}
                      />
                      <p className="mt-2">{intl.formatMessage(messages.rightRail)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </ScanDetailsCard>

            <ScanDetailsCard title={intl.formatMessage(messages.carString)}>
              <div className="row">
                <ScanDetailsLabel label={intl.formatMessage(messages.carBrandString)} value={scan.Car && scan.Car.brand} />
                <ScanDetailsLabel label={intl.formatMessage(messages.carModelString)} value={scan.Car && scan.Car.model} />
                <ScanDetailsLabel label={intl.formatMessage(messages.licensePlateString)} value={scan.Car && scan.Car.licensePlate} />
                <ScanDetailsLabel label={intl.formatMessage(messages.doorCountString)} value={scan.Car && scan.Car.doorCount} />
              </div>
            </ScanDetailsCard>

            <ScanDetailsCard title={intl.formatMessage(messages.customerString)}>
              <div className="row">
                <ScanDetailsLabel label={intl.formatMessage(messages.countryString)} value={scan.Customer && scan.Customer.country} />
                <ScanDetailsLabel label={intl.formatMessage(messages.nameString)} value={scan.Customer && scan.Customer.firstName} />
                <ScanDetailsLabel label={intl.formatMessage(messages.lastnameString)} value={scan.Customer && scan.Customer.lastName} />
                <ScanDetailsLabel label={intl.formatMessage(messages.emailString)} value={scan.Customer && scan.Customer.email} />
                <ScanDetailsLabel label={intl.formatMessage(messages.telephoneString)} value={scan.Customer && scan.Customer.phoneNumber} />
              </div>
            </ScanDetailsCard>

            <ScanDetailsCard title={intl.formatMessage(messages.reportString)}>
              <div className="row">
                {reportFiles.length > 0 ? (
                  reportFiles.map((file, i) => (
                    <div
                      key={i}
                      className="col-md-2 text-center cursor-pointer"
                    >
                      <span onClick={openReportFile(file)}>
                        <img
                          src="../assets/images/word-icon2.png"
                          width="100"
                          alt={`report-${i + 1}`}
                        />
                        <p className="text-center">Report {i + 1}</p>
                      </span>
                    </div>
                  ))
                ) : (
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-control-static">
                        {" "}
                        <FormattedMessage {...messages.noreportString} />
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </ScanDetailsCard>

            <ScanDetailsCard title={intl.formatMessage(messages.followersString)}>
              <button
                onClick={openFModal}
                className="btn btn-rounded btn-success"
              >
                <FormattedMessage {...messages.addFString} />
              </button>
              <div className="row mt-3">
                {scan.Followers.length > 0 ? (
                  scan.Followers.map((f, i) => {
                    return (
                      <div key={i} className="col-md-6">
                        <div className="form-group">
                          <label className="control-label text-right">
                            {f.email}
                          </label>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-control-static">
                        {" "}
                        <FormattedMessage
                          {...messages.nofollowersString}
                        />
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </ScanDetailsCard>

            {scan.Snapshots.length > 0 && (
              <ScanDetailsCard title={intl.formatMessage(messages.ssnapshotString)}>
                <div className="row">
                  {scan.Snapshots.map((snap, index) => (
                    <div
                      key={index}
                      className="col-md-4 cursor-pointer mb-4"
                    >
                      <img
                        onClick={openLightbox(snap)}
                        alt={`snapshot-${index + 1}`}
                        className="w-100"
                        src="../assets/images/avtocar.jpg"
                      />
                    </div>
                  ))}
                  {viewerIsOpen && (
                    <ModalGateway>
                      <Modal onClose={closeLightbox}>
                        <Carousel
                          currentIndex={0}
                          views={[
                            { source: currentImage, caption: "snapshot" }
                          ]}
                        />
                      </Modal>
                    </ModalGateway>
                  )}
                </div>
              </ScanDetailsCard>
            )}
          </div>
        </div>
      </div>
      {isFollowersModalOpen && (
        <FollowersModal
          initialValues={{ followers: scan.Followers }}
          addNewFollowers={addNewFollowers}
          openModal={isFollowersModalOpen}
          closeModal={closeFModal}
          messages={messages}
        />
      )}
      {isPlayerModalOpen && (
        <ModalVideo
          openModal={isPlayerModalOpen}
          closeModal={closeVideoModal}
          scanId={match.params.id}
          scanCreatedAt={scan.createdAt}
          messages={messages}
          video_link={videoURL}
        />
      )}
    </div>
  );
};

export default injectIntl(ScanDetailsPage);
