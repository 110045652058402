import React from "react";
import Modal from "react-modal";
import { Field, reduxForm } from "redux-form/immutable";
import { FormattedMessage, injectIntl } from "react-intl";
import { otherModalStyles } from "../../../constants";
import { validatePassword } from "../../../helper/validationHelper";

const ChangePasswordForm = ({
  openModal,
  closeModal,
  handleSubmit,
  intl,
  updatePassword,
  messages,
}) => {
  const submitF = (data) => {
    data = data.toJS();

    let fdata = {};

    if (data.password) {
      fdata.password = data.password;
    }

    updatePassword(fdata);

    closeModal();
  };

  return (
    <Modal
      isOpen={openModal}
      contentLabel={intl.formatMessage({
        id: "app.components.Users.newPassword",
      })}
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={otherModalStyles}
    >
      <div className="modal-wrapper">
        <div className="card-header bg-info">
          <h4 className="m-b-0 text-white">
            <FormattedMessage {...messages.changePassword} />
          </h4>
        </div>
        <div className="card-body">
          <form
            className="form-horizontal form-material"
            onSubmit={handleSubmit(submitF)}
          >
            <Field
              name="password"
              label={intl.formatMessage({
                id: "app.components.Users.newPassword",
              })}
              component={renderField}
              type="password"
              intl={intl}
            />
            <Field
              name="confirm_password"
              label={intl.formatMessage({
                id: "app.components.Users.confirmPassword",
              })}
              component={renderField}
              type="password"
              intl={intl}
            />
            <div className="text-right">
              <button type="submit" className="btn btn-success">
                <i className="fa fa-check"></i> Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

const renderField = ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="col-md-6">
    <div className={`form-group ${touched && error && "has-danger"}`}>
      <label className="control-label">{label}</label>
      <input
        {...input}
        type={type}
        className="form-control"
        placeholder={placeholder}
      />
      {touched && error && (
        <span className="form-control-feedback">{error}</span>
      )}
    </div>
  </div>
);

const validate = (values, props) => {
  const passwordErrors = validatePassword(
    values.get("password"),
    values.get("confirm_password"),
    props.intl
  );
  const errors = { ...passwordErrors };

  return errors;
};

export default reduxForm({
  validate,
  form: "ChangePasswordForm",
})(injectIntl(ChangePasswordForm));
