export const validatePassword = (password, confirmPassword) => {
    const errors = {};
  
    if (password !== confirmPassword) {
      errors.confirm_password = "Passwords must match";
    }
  
    if (password) {
      if (password.length < 8 || password.length > 64) {
        errors.password = "Password must be between 8 and 64 characters";
      } else if (!/[A-Z]/.test(password)) {
        errors.password = "Password must contain at least one uppercase letter";
      } else if (!/[a-z]/.test(password)) {
        errors.password = "Password must contain at least one lowercase letter";
      } else if (!/\d/.test(password)) {
        errors.password = "Password must contain at least one number";
      } else if (!/\W/.test(password)) {
        errors.password = "Password must contain at least one special character";
      }
    } else {
      errors.password = "Password is required";
    }
  
    return errors;
  };